.depositeWrapper {
    width: 100% !important;
}

.depositeContainer {
    padding: 16px;
}

.depositeTitle {
    font-size: 16px;
    width: 100%;
    padding: 8px;
    text-align: center;
    color: var(--primary);
    font-weight: bold;
}

.depositeAmountContainer p {
    opacity: 0.5;
    font-size: 14px;
    font-weight: 600;
}

.inputDisabled {
    height: 40px;
    width: 100%;
    font-size: 18px;
    padding-left: 8px;
    color: black;
    border: 1px solid #c4c4c4;
}

.copyButton {
    margin-top: 16px !important;
    width: 100% !important;
    border-radius: 0px !important;
    border: none !important;
    background-color: var(--primary) !important;
    color: var(--white);
    font-weight: bold;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}