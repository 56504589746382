.betTypesWrapper {
    border-radius: 8px;
    padding: 0;
    margin-bottom: 32px;
}

.betTypesTitle {
    background-color: var(--secondary);
    color: var(--white);
    padding: 8px;
    border-radius: 0px;
    text-align: center;
}

.betTypesContainer {
    padding: 8px;
}

.betTypes {
    color: var(--white);
    margin-bottom: 4px;
    cursor: pointer;
}

.betTypeContainer {
    display: flex;
    width: 100%;
    background-color: var(--primary) !important;
    margin: 8px 0px;
    padding: 8px;
}

.actionLinksContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.betTypeText {
    margin: 0 !important;
    color: var(--white);
}