.profileFieldsWrapper {
    padding: 32px 0px;
}

.inputLabel {
    color: var(--primary);
    font-size: 16px;
    width: 100%;
    padding: 8px;
    text-align: center;
    font-weight: bold;
}

.input,
.input:focus {
    border-radius: 0px;
}

.inputD {
    border-radius: 0px;
}

.editSubmitButton {
    width: 100% !important;
    background-color: var(--primary) !important;
    border: none !important;
    padding: 8px !important;
    border-radius: 0px !important;
    font-weight: bold !important;
    color: var(--white) !important;
}