.withdrawSubmitButton {
    border-radius: 0px !important;
    background-color: var(--primary) !important;
    padding: 12px 0px;
    color: var(--white) !important;
    font-weight: bold;
    border: none;
    width: 100%;
}

.successMessageContainer {
    width: 100%;
    padding: 8px 0px;
}

.successMessageContainer p {
    padding: 8px;
    color: #228B22;
    font-size: 18px;
    font-weight: 600;
}

.errorMessageContainer {
    width: 100%;
}

.errorMessageContainer p {
    padding: 8px;
    color: red;
    font-size: 18px;
    font-weight: 600;
}

.maxButton,
.maxButton:hover,
.maxButton:focus {
    background-color: var(--primary);
    border: none;
}

.withdrawSentence {
    color: var(--secondary);
    font-weight: bold;
}

.withdrawLabel {
    width: 100%;
    padding: 8px;
    text-align: center;
}

.withdrawLabel h5 {
    font-size: 16px;
    color: var(--primary);
    font-weight: bold;
    margin: 0;
}