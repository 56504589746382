.reviewsWrapper {
    padding: 32px 0px;
}

.reviewsTitle {
    background-color: var(--secondary);
    color: var(--white);
    margin: 24px 16px;
    padding: 8px;
    border-radius: 0px;
    text-align: center;
}

.ratingContainer {
    padding: 16px;
    display: flex;
    align-items: flex-end;
}

.rating {
    color: orange;
    font-weight: bold;
    padding-right: 8px;
    margin: 0;
}

.checked {
    color: orange;
    padding: 4px;
}

.reviews {
    font-size: 12px;
    opacity: 0.8;
    font-weight: bold;
    margin: 0;
}

.reviewsContainer {
    width: 100%;
    height: 500px;
    overflow-y: scroll;
    padding: 16px;
}

.reviewContainer {
    padding: 16px;
}

.reviewAvatarName {
    display: flex;
    align-items: center;
}

.reviewAvatar {
    background-color: var(--primary);
    color: var(--white);
    font-weight: bold;
    border-radius: 100px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}

.reviewName {
    padding-left: 8px;
}

.reviewComment {
    padding: 16px 0px 16px 50px;
}