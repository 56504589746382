.tableSportsTitle {
     background-color: var(--secondary);
     color: var(--white);
     margin: 24px 0px;
     padding: 8px;
     border-radius: 0px;
     text-align: center;
}

.gameTableContainer {
     height: 40vh;
     border-radius: 0px;
     overflow-y: auto;
     padding: 0px;
}

.gameTableContainer::-webkit-scrollbar {
     display: none;
}

.tableRow {
     color: var(--gray);
}

.tableRow td {
     padding: 16px 8px;
}

.tableTimeCell {
     font-size: 14px;
     font-weight: bold;
     background-color: var(--primary) !important;
     color: var(--white);
}

.tableCell {
     font-size: 14px !important;
     font-weight: bold !important;
}

.tableCell p {
     margin: 0 !important;
}