.slideShow {
    width: 100%;
    height: 100vh;
    margin-top: 32px;
}

.mySwiper {
    width: 100%;
    height: 85vh;
}

.swiper {
    width: 100%;
}

.swiper img {
    width: 100%;
    height: 100%;
}