.usaLeagueWrapper {
    border-radius: 8px;
    padding: 0;
    margin-bottom: 32px;
}

.usaLeagueTitle {
    background-color: var(--secondary);
    color: var(--white);
    padding: 8px;
    border-radius: 0px;
    text-align: center;
}

.usaLeaguesContainer {
    padding: 8px;
}

.usaLeague {
    color: var(--primary);
    margin-bottom: 4px;
    cursor: pointer;
}

.usaLeagueContainer {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--primary);
    margin: 8px 0px;
    padding: 8px;
}

.usaLeagueText {
    margin-bottom: 0 !important;
    margin-left: 16px;
    color: var(--white);
}