.newsTitle {
    padding: 8px;
    color: var(--white);
    background-color: var(--secondary);
    text-align: center;
}

.newsImage {
    margin-bottom: 8px;
}

.newsContainer {
    padding: 16px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: 350px;
}

.newsBody {
    margin-left: 16px;
    width: 100%;
}

.newsBodyTitle {
    text-decoration: underline;
}

.newsBodyDateButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.newsDate {
    font-size: 12px;
    font-weight: bold;
    margin: 0;
}

.newsReadMoreButton,
.newsReadMoreButton:hover,
.newsReadMoreButton:focus {
    background-color: var(--primary);
    border: none;
    border-radius: 0px !important;
}

.newsReadMoreButton a,
.newsReadMoreButton:hover a,
.newsReadMoreButton:focus a {
    color: var(--white) !important;
    text-decoration: none !important;
}