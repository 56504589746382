.sprotsLinkWrapper {
    padding: 0;
    margin-bottom: 32px;
}


.sportsLinkTitle {
    background-color: var(--secondary);
    color: var(--white);
    padding: 8px;
    border-radius: 0px !important;
    text-align: center;
}

.sportsLinkContainer {
    padding: 8px;
}

.sportsLink {
    color: var(--white) !important;
    background-color: var(--primary);
    padding: 8px;
    margin-bottom: 4px;
    cursor: pointer;
}

.actionLinksContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}