.sprotsLinkWrapper {
    border-radius: 8px;
    padding: 0;
    margin-bottom: 32px;
}

.sportsLinkTitle {
    background-color: var(--secondary);
    color: var(--white);
    padding: 8px;
    border-radius: 0px !important;
}

.sportsLinkContainer {
    padding: 8px;
}

.sportsLink {
    color: var(--blue);
    margin-bottom: 4px;
    cursor: pointer;
}

.actionLinksContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}