.footerWrapper {
    background-color: var(--primary);
    height: auto;
}

.linksContainer {
    color: var(--white);
    margin-top: 32px;
    margin-left: 16px;
    font-weight: 600;
}

.socialMediaWrapper {
    text-align: left;
}

.socialMediaContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copyRight {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    background-color: var(--primary);
    height: 40px;
    font-weight: 600;

}

.footerText {
    font-size: 14px;
    text-align: left;
    margin-top: 8px
}

.SocialMediaIcons {
    margin-right: 16px;
    cursor: pointer
}

@media screen and (max-width:767.5px) {
    .socialMediaWrapper {
        text-align: center !important;
    }

    .footerText {
        text-align: center;
        margin-top: 16px;
    }
}

.footerSportsLinksContainer,
.footerTypesLinksContainer,
.footerLeaguesLinksContainer {
    margin-top: 24px;
    margin-bottom: 32px;
    text-align: center;
    border-right: 2px solid white;
}

@media screen and (max-width:992px) {

    .footerSportsLinksContainer,
    .footerTypesLinksContainer,
    .footerLeaguesLinksContainer {
        border-right: 2px solid white;

    }
}

.footerSportsLinksContainer p,
.footerTypesLinksContainer p,
.footerLeaguesLinksContainer p {
    color: var(--white);
    cursor: pointer
}

.footerSportsLinksContainer h4,
.footerTypesLinksContainer h4,
.footerLeaguesLinksContainer h4 {
    color: var(--white);
}

.footerSocialMediaContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 32px;
}

.footerSocialMediaContainer div {
    padding: 0px 8px;
    cursor: pointer
}